import "core-js/modules/es.object.to-string.js";
import Mixin from "@/utils/mixin";
import { columns } from "./config/index";
import { AwardsApi } from "@/api";
import UpdateNote from './component/UpdateNote';
export default {
  name: "awardsManagement",
  components: {
    UpdateNote: UpdateNote
  },
  data: function data() {
    return {
      page: 1,
      size: 10,
      loading: false,
      dataSource: [],
      columns: columns,
      total: 0,
      previewImage: '',
      previewVisible: false,
      modalTitle: '',
      modalVisible: false,
      editId: ''
    };
  },
  mixins: [Mixin],
  computed: {},
  created: function created() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      var _params = {
        size: this.size,
        page: this.page
      };
      AwardsApi.findpage(_params).then(function (res) {
        _this2.loading = false;
        _this2.dataSource = res.list;
        _this2.total = res.count;
      }).catch(function (err) {
        _this2.dataSource = [];
      }).finally(function () {
        setTimeout(function () {
          _this2.loading = false;
        }, 3000);
      });
    },
    preview: function preview() {
      var previewImage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      this.previewImage = previewImage;
      this.previewVisible = true;
    },
    previewHandleCancel: function previewHandleCancel() {
      this.previewVisible = false;
    },
    handleDetail: function handleDetail(record, type) {
      var _this = this;
      _this.modalTitle = '备注填写';
      _this.modalVisible = true;
      _this.editId = record.id;
    },
    handleAddCancle: function handleAddCancle() {
      this.modalVisible = false;
    },
    handleAddSubmit: function handleAddSubmit() {
      this.modalVisible = false;
      this.getList();
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    }
  }
};