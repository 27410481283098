import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form", {
    attrs: {
      form: _vm.editorForm
    }
  }, [_c("a-form-item", [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["note", {
        rules: [{
          required: true,
          message: "请输入备注信息!"
        }],
        initialValue: _vm.note
      }],
      expression: "[\n                    'note',\n                    {\n                      rules: [\n                          { required: true, message: '请输入备注信息!' },\n                      ],\n                      initialValue: note\n                    },\n                  ]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请填写备注信息...",
      rows: 5
    },
    on: {
      change: function change(e) {
        e.target.value = e.target.value.trim();
        return e;
      }
    }
  })], 1), _c("a-form-item", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("a-button", {
    staticClass: "btn btn_l",
    staticStyle: {
      background: "#F5F5F5",
      border: "0"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s("提交"))])], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };