var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return it.id;
      },
      scroll: {
        x: 1096
      },
      loading: _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "img",
      fn: function fn(text, record) {
        return [record.img ? _c("img", {
          staticClass: "product_img",
          attrs: {
            src: record.img
          },
          on: {
            click: function click($event) {
              return _vm.preview(record.img);
            }
          }
        }) : _c("span", [_vm._v(" --- ")])];
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return [_c("span", [_c("a", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["awards:btn:note"],
            expression: "['awards:btn:note']"
          }],
          on: {
            click: function click($event) {
              return _vm.handleDetail(record, 1);
            }
          }
        }, [_vm._v("备注")])])];
      }
    }, {
      key: "note",
      fn: function fn(text) {
        return [text && text.length > 30 ? _c("a-tooltip", {
          attrs: {
            placement: "top"
          }
        }, [_c("template", {
          slot: "title"
        }, [_vm._v(" " + _vm._s(text) + " ")]), _vm._v(" " + _vm._s(text.substr(0, 30)) + " ")], 2) : _vm._e(), text && text.length < 30 ? _c("span", [_vm._v(_vm._s(text))]) : _vm._e(), !text ? _c("span", [_vm._v("---")]) : _vm._e()];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  }), _c("DPopup", {
    attrs: {
      title: _vm.modalTitle,
      comVisible: _vm.modalVisible,
      modalWidth: "420px"
    },
    scopedSlots: _vm._u([{
      key: "form",
      fn: function fn() {
        return [_c("UpdateNote", {
          attrs: {
            editId: _vm.editId
          },
          on: {
            handleAddCancle: _vm.handleAddCancle,
            handleAddSubmit: _vm.handleAddSubmit
          }
        })];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };